.image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.real {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.real-hidden {
  opacity: 0;
}

.real-visible {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
